import { Chain, type EVMChain, WalletOption } from '@swapkit/helpers'
import { isEvmChain, isKeplrChain, isXdefiChain } from './swapkit'
import type { ChainProps, WalletProps } from '~/types/modal.walletconnect'
import { RangoChain } from '~/types/rango'
import type {
  KeplrChain as KeplrChainId,
  WalletChain,
  XdefiChain as XdefiChainId,
} from '~/wallets/swapkit'

export type SwapkitConnectChainId =
  | Chain.Arbitrum
  | Chain.Avalanche
  | Chain.Bitcoin
  | Chain.Cosmos
  | Chain.Dash
  | Chain.Dogecoin
  | Chain.Ethereum
  | Chain.Kujira
  | Chain.Litecoin
  | Chain.Maya
  | Chain.THORChain
// | Chain.Polkadot // * Polkadot is not yet supported by Swapkit
export type ConnectChainId = SwapkitConnectChainId | RangoChain.Solana
export type ConnectChains = {
  [K in ConnectChainId]: ChainProps & { readonly id: K }
}
export type ConnectChain = ConnectChains[ConnectChainId]
export const connectChainsRecord: ConnectChains = {
  [Chain.Arbitrum]: {
    id: Chain.Arbitrum,
    src: '/tokens/arbitrum.png',
    title: 'Arbitrum',
    status: 'selected',
  },
  [Chain.Avalanche]: {
    id: Chain.Avalanche,
    src: '/tokens/avax.svg',
    title: 'Avalanche',
    status: 'selected',
  },
  [Chain.Bitcoin]: {
    id: Chain.Bitcoin,
    src: '/tokens/btc.png',
    title: 'Bitcoin',
    status: 'selected',
  },
  [Chain.Cosmos]: {
    id: Chain.Cosmos,
    src: '/tokens/atom.svg',
    title: 'Cosmos',
    status: 'selected',
  },
  [Chain.Dash]: {
    id: Chain.Dash,
    src: '/tokens/dash.png',
    title: 'Dash',
    status: 'selected',
  },
  [Chain.Dogecoin]: {
    id: Chain.Dogecoin,
    src: '/tokens/doge.png',
    title: 'Dogecoin',
    status: 'selected',
  },
  [Chain.Ethereum]: {
    id: Chain.Ethereum,
    src: '/tokens/ethereum.png',
    title: 'Ethereum',
    status: 'selected',
  },
  [Chain.Kujira]: {
    id: Chain.Kujira,
    src: '/tokens/kuji.png',
    title: 'Kujira',
    status: 'selected',
  },
  [Chain.Litecoin]: {
    id: Chain.Litecoin,
    src: '/tokens/ltc.png',
    title: 'Litecoin',
    status: 'selected',
  },
  [Chain.Maya]: {
    id: Chain.Maya,
    src: '/tokens/maya.png',
    title: 'Maya',
    status: 'selected',
  },
  // [Chain.Optimism]: {
  //   id: Chain.Optimism,
  //   src: '/tokens/op.webp',
  //   title: 'Optimism',
  //   status: 'selected',
  // },
  // [Chain.Polygon]: {
  //   id: Chain.Polygon,
  //   src: '/tokens/matic.webp',
  //   title: 'Polygon',
  //   status: 'selected',
  // },
  // {
  //   id: Chain.Polkadot,
  //   src: '/tokens/polkadot.svg',
  //   title: 'Polkadot',
  //   status: 'selected',
  // },
  [Chain.THORChain]: {
    id: Chain.THORChain,
    src: '/tokens/rune.png',
    title: 'Thorchain',
    status: 'selected',
  },
  [RangoChain.Solana]: {
    id: RangoChain.Solana,
    src: '/tokens/solana.png',
    title: 'Solana',
    status: 'selected',
  },
}
export const connectChains = Object.values(connectChainsRecord)
type FilterChain<C extends ConnectChain, W extends WalletChain> = C extends { id: W } ? C : never
export type KeystoreChain = ConnectChain
export const keystoreChains = connectChains
export type XdefiChain = FilterChain<ConnectChain, XdefiChainId>
export const xdefiChains = connectChains.filter((chain): chain is XdefiChain => {
  return isXdefiChain(chain.id)
})
export type PhantomChain = FilterChain<ConnectChain, RangoChain.Solana>
export const phantomChains = connectChains.filter((chain): chain is PhantomChain => {
  return chain.id === RangoChain.Solana
})
export type EvmChain = FilterChain<ConnectChain, EVMChain>
export const evmChains = connectChains.filter((chain): chain is EvmChain => {
  return isEvmChain(chain.id)
})
export type KeplrChain = FilterChain<ConnectChain, KeplrChainId>
export const keplrChains = connectChains.filter((chain): chain is KeplrChain => {
  return isKeplrChain(chain.id)
})
export type WalletChains = {
  [WalletOption.XDEFI]: XdefiChain[]
  [WalletOption.PHANTOM]: PhantomChain[]
  [WalletOption.METAMASK]: EvmChain[]
  [WalletOption.KEPLR]: KeplrChain[]
  [WalletOption.KEYSTORE]: KeystoreChain[]
  'from-phrase': KeystoreChain[]
  'create-new': KeystoreChain[]
}
export type WalletId = keyof WalletChains
export type Wallets = {
  [K in WalletId]: WalletProps & { id: K; readonly chains: WalletChains[K] }
}
export type Wallet = Wallets[WalletId]
export const walletsRecord: Wallets = {
  [WalletOption.XDEFI]: {
    id: WalletOption.XDEFI,
    name: 'XDEFI/Ctrl',
    logo: '/wallets/xdefi.svg',
    category: 'software',
    type: WalletOption.XDEFI,
    chains: xdefiChains,
    disabled: false,
  },
  [WalletOption.PHANTOM]: {
    id: WalletOption.PHANTOM,
    name: 'Phantom',
    logo: '/wallets/phantom.svg',
    category: 'software',
    type: WalletOption.PHANTOM,
    chains: phantomChains,
    disabled: false,
  },
  [WalletOption.METAMASK]: {
    id: WalletOption.METAMASK,
    name: 'Metamask',
    logo: '/wallets/metamask.svg',
    category: 'software',
    type: WalletOption.METAMASK,
    chains: evmChains,
    disabled: false,
  },
  [WalletOption.KEYSTORE]: {
    id: WalletOption.KEYSTORE,
    name: 'Keystore',
    logo: '/wallets/keystore.svg',
    type: WalletOption.KEYSTORE,
    category: 'file',
    chains: keystoreChains,
    disabled: false,
  },
  'from-phrase': {
    id: 'from-phrase',
    name: 'From Phrase',
    logo: '/wallets/fromphrase.svg',
    category: 'file',
    type: WalletOption.KEYSTORE,
    chains: keystoreChains,
    disabled: false,
  },
  'create-new': {
    id: 'create-new',
    name: 'Create New Wallet',
    logo: '/wallets/createnewwallet.svg',
    category: 'file',
    type: WalletOption.KEYSTORE,
    chains: keystoreChains,
    disabled: false,
  },
  [WalletOption.KEPLR]: {
    id: WalletOption.KEPLR,
    name: 'Keplr',
    logo: '/wallets/keplr.svg',
    category: 'software',
    type: WalletOption.KEPLR,
    chains: keplrChains,
    disabled: false,
  },
  // [WalletOption.TALISMAN]: {
  //   id: WalletOption.TALISMAN,
  //   name: 'Talisman',
  //   logo: '/wallets/talisman.svg',
  //   category: 'software',
  //   type: WalletOption.TALISMAN,
  //   chains: [],
  //   disabled: false,
  // },
  // [WalletOption.WALLETCONNECT]: {
  //   id: WalletOption.WALLETCONNECT,
  //   name: 'Wallet Connect',
  //   logo: '/wallets/walletconnect.svg',
  //   category: 'software'
  //   type: WalletOption.WALLETCONNECT,
  //   chains: [],
  //   disabled: false,
  // },
  // [WalletOption.TRUSTWALLET_WEB]: {
  //   id: WalletOption.TRUSTWALLET_WEB,
  //   name: 'Trust Wallet',
  //   logo: '/wallets/trustwallet.svg',
  //   category: 'software',
  //   type: WalletOption.TRUSTWALLET_WEB,
  //   chains: [],
  //   disabled: false,
  // },
  // [WalletOption.LEDGER]: {
  //   id: WalletOption.LEDGER,
  //   name: 'Ledger',
  //   logo: '/wallets/ledger.svg',
  //   category: 'hardware',
  //   type: WalletOption.LEDGER,
  //   chains: [],
  //   disabled: false,
  // },
  // [WalletOption.TREZOR]: {
  //   id: WalletOption.TREZOR,
  //   name: 'Trezor',
  //   logo: '/wallets/trezor.svg',
  //   category: 'hardware',
  //   type: WalletOption.TREZOR,
  //   chains: [],
  //   disabled: false,
  // },
}
export const wallets: Array<Wallet> = Object.values(walletsRecord)
